@import './colors';
// @import 'kether-shared-lib/scss/base/colors';

// TODO : split !
@import 'kether-shared-lib/scss/index';

// not imported in shared because Predict uses older bulma version with different path
// TODO: this import brakes buyer-recap build, for now used mainly for is-uppercase, maybe has-text-centered
// @import "~bulma/sass/helpers/typography.sass";

@import './Buttons/pbutton.scss';
@import './flink.scss';

// Other 'global' classes

.svg-fill-primary {
  fill: $primary;
}
