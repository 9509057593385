
@import "../base/layouts";
@import "../base/icons";
@import '../base/typography';

.p-stepper-round {
  @extend .p-icon;
  z-index: 2; // Over the line
  font-size: $size-9;
}
