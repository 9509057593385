
@import './base/colors';
@import './base/typography';
@import './base/borders';
@import './base/layouts';
@import './base/clickable';

/*
@import 'base/layouts';
@import 'animations';
@import 'icons';
@import 'components/button';
@import 'components/form';
@import 'components/input';

// Use Bulma mixins like until
@import "~bulma/sass/utilities/mixins.sass";
*/

/*
body {
  font-family: $main-family; // 'Open sans', sans-serif;
}
 */

/*
// Let's try
// https://web.dev/font-display/?utm_source=lighthouse&utm_medium=unknown
body {
    @font-face {
      font-display: swap;
    }
} //*/

.new-body {
  // font-family: $family-sans-serif;
  font-size: $size-7;
  color: $grey-title-text; // TODO : remove ?
}

// TODO : rename to p-action ?
.link {
    @extend .p-clickable;
  //cursor: pointer;
  color: $secondary-color;
}

// TODO : remove
$sidebar-width: 240px;
// $topbar-height: 65px;

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

.p-overlappingProgressBarWrapper {
  position: relative;
}

.p-overlappingProgressBar {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: $progress-bar-background-color;
}

// Shared with PModal and Bulma

$modal-overlay-color: rgba(#687782, .6);

// TODO : review, use p-divider
.p-horizontal-bar {
  height: 1px;
  border-top: 1px solid $white-6;
}

.p-new-background {
  background-color: $background-filter-color !important;
}

.p-border-down {
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: $white-6;
}

// TODO : move to FileIcon ?
// TODO : default ?
.p-default-icon {
  stroke: $grey-2;
}

.svg-fill-blue {
  fill: $green-2;
}

.svg-fill-grey {
  fill: #d8d8d8; // No other grey in the front
}

.svg-stroke-blue {
  stroke: $green-2;
}

.svg-large-stroke-blue {
  fill: none;
  stroke: $green-2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.9px;
}

.svg-medium-stroke-blue {
  fill: none;
  stroke: $green-2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.58px;
}

.svg-fill-white {
    // TODO : on blue background, why not used default $text-light-color ?
  fill: $white-0;
}

.svg-fill-light-white {
  // TODO : on blue background, why not used default $text-light-color ?
  fill: $white-1;
}

// Messages

// TODO : rename to baneer
.p-message {
  @extend .p-padding-5;
  border-radius: $border-radius;
}

.p-new-error-message {
  // TODO : no (text / stoke) color ?
  @extend .p-message;
  @extend .p-error-border;
  background-color: rgba($error-color, 0.08);
}

.p-warning-message {
  @extend .p-message;
  border: 1px solid rgba($warning-indicator-color, 0.3);
  background-color: rgba($warning-indicator-color, 0.08);
}

.p-background-blue {
  background: $new-purple !important;
  color: $text-light-color;
}

// TODO : rename to banner ?
.p-ok-message {
  @extend .p-message;
  @extend .p-ok-border;
  background-color: rgba($green-2, 0.08);
}

// TODO : shared globally ?
// TODO : rename to p-top-down-divider ?
.p-divider {
  height: 1px;
  // TODO : duplicated
  border-top: solid 1px $divider-color;
}

.p-box {

  // Make sure we do not override
  border-radius: $border-radius;

  // opacity: 0.5;
  // background-color: $white-4;
  background-color: $white-2; // To replace the combination of opacity and background
}

// TODO : shared ?
.p-box-in-box {
  border-radius: $border-radius;
  background-color: $white-4; // Same as background
}

// Our logo
.p-logo {
  width: 128px;
  // height: 32px;
  // margin: 0 0 24px;
}

.p-white-bg {
  background-color: $white-0;
}

.p-page-box {
  @extend .p-padding-3;
  @extend .p-white-bg;
  border-radius: $border-radius;
}

.p-page-box:not(:last-child) {
  @extend .p-margin-bottom-4;
}

// A fix hello sign
.x-hellosign-embedded {
  z-index: 999;
}

.p-ok {
  background-color: $green-2;
  color: $white-0;
  fill: $white-0;
}

.p-ok-premium {
  background-color: $new-purple;
  color: $white-0;
  fill: $white-0;
}

.p-danger {
  background-color: $danger;
  color: $white-0;
  fill: $white-0;
}

.p-warn {
  background-color: $warning;
  color: $white-0;
  fill: $white-0;
}

.p-warn-header {
  background-color: rgba(247,162,83,0.08);
}

.p-warn-icon {
  stroke: $warning;
  color: $warning;
}

.p-info {
  background-color: rgba(50, 218, 195, 0.08);
  color: $green-2;
  border: 1px solid rgba(50, 218, 195, 0.5);
}

.p-not-done {
  background-color: $white-6;
  // Color: leave default ?
}

.p-light-blue-banner {
  border: 1px solid rgba(50, 218, 195, 0.5); // TODO : revert engineering
  border-radius: $border-radius;
  background-color: rgba(50, 218, 195, 0.08); // TODO : revert engineering
  // box-shadow: 0 10px 15px 0 $white-6;
}

.p-table {

    // TODO : rename to p-min-width-column ?
    .minSize {
        width: 1%;
    }

    // I wanted to put it in th .minSize but it is not working
    .p-no-wrap {
      white-space: nowrap !important;
    }

    th {

        // https://stackoverflow.com/questions/3440815/vertically-align-a-div-within-a-table-th
        vertical-align: middle !important;

        // Slight override
        padding-left: 1.2em;
        padding-right: 1.2em;
    }

    tr {
        // https://stackoverflow.com/questions/3440815/vertically-align-a-div-within-a-table-th
        // vertical-align: middle !important;
    }

    td {
        // https://stackoverflow.com/questions/25343864/vertical-align-inside-table-row
        //vertical-align: middle !important;

        // Slight override
        // Slight override
        padding-left: 1.2em;
        padding-right: 1.2em;
    }
}

// TODO : why aren't they symmetric ?

.p-arrow-up {
    border-style: solid;
    border-width: 0 6px 6px 6px;
    &.p-clickable {
        border-color: transparent transparent $secondary transparent;
    }
    &:not(.p-clickable) {
      border-color: transparent transparent $grey-4 transparent;
    }
}

.p-arrow-down {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    &.p-clickable {
        border-top: 6px solid $secondary;
    }
    &:not(.p-clickable) {
        border-top: 6px solid $grey-4;
    }
}

.p-arrow-right {
  @extend .p-clickable;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  border-left: 6px solid $secondary;
}

// Related to table

.p-striped {
  background-color: $white-4 !important;
}

// TODO : rename to
.p-non-striped {
  background-color: $white-0 !important;
}

.p-bg-white {
  background-color: $white-0 !important;
}

// https://stackoverflow.com/questions/9258754/spacing-between-thead-and-tbody/9260996
.p-thead-tbody-split {
    tbody:before {
        content: "-";
        display: block;
        line-height: 1em;
        color: transparent;
    }

    th {
      border-top-width: 1px !important;
    }
}

// An extension of previous p-after-hero
// Should be probably merged
.p-illustration-zone {
  box-shadow: 0 0 50px rgba($main-dark-purple, 0.35);
}

.p-vertical-align-top {
  vertical-align: top;
}
