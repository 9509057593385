
@import '../base/borders';

// TODO : not sure why this is needed

.p-table {
  // The table has rounded corner and the default border style
  border: $border-default;
  border-radius: $border-radius;

  // By default, border-collapse make the inner cell border properties take
  // precedence over the outer table properties. Hence the border-radius is not
  // applied properly. Disabelling border-collapse allow to use border-radius
  // on the table element.
  border-collapse: separate;
}

.p-il-table {
  td {
    vertical-align: middle !important;
  }
}
