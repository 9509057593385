// This file should contains all the variables and mixins.
// /!\ This file is loaded with every component.
@import "./utilities/power";

//@import "~bulma/css/bulma.min.css";

// Custom styles
@import "./style";

@import "./base/icons";
@import "./base/clickable";
@import "./components/stepper";
@import './components/table';
@import "./times";

@import "./variables-override";

// Override Bulma's default mixins
// @import "override/mixins.scss";

// @import "~bulma-slider";

@import "~bulma/sass/utilities/_all.sass";
// @import "~bulma/sass/utilities/initial-variables.sass";
// @import "~bulma/sass/utilities/derived-variables.sass";
// @import "~bulma/sass/utilities/animations.sass";
// @import "~bulma/sass/utilities/controls.sass";
// @import "~bulma/sass/utilities/functions.sass";
// @import "~bulma/sass/utilities/mixins.sass";

// Override Bulma's default mixins
@import "./override/mixins";

@import "~bulma/sass/base/_all.sass";
//// @import "~bulma/sass/base/generic.sass";
//// @import "~bulma/sass/base/helpers.sass";
//// @import "~bulma/sass/base/minireset.sass";

@import "~bulma/sass/grid/_all.sass";

@import "~bulma/sass/layout/hero.sass";

@import "~bulma/sass/components/level.sass";
@import "~bulma/sass/components/modal.sass";
@import "~bulma/sass/components/navbar.sass";
@import "~bulma/sass/components/panel.sass";

@import "~bulma/sass/elements/box.sass";
@import "~bulma/sass/elements/button.sass";
@import "~bulma/sass/elements/container.sass";
@import "~bulma/sass/elements/content.sass";
@import "~bulma/sass/elements/image.sass";
@import "~bulma/sass/elements/other.sass";
@import "~bulma/sass/elements/progress.sass";
@import "~bulma/sass/elements/table.sass";
@import "~bulma/sass/elements/tag.sass";
@import "~bulma/sass/elements/title.sass";

@import "~bulma/sass/form/shared.sass";
@import "~bulma/sass/form/checkbox-radio.sass";
@import "~bulma/sass/form/input-textarea.sass";
@import "~bulma/sass/form/select.sass";
@import "~bulma/sass/form/tools.sass";

// @import "~bulma/sass/helpers/typography.sass"; // Not enabled in Predict


html {
  // Override bulma scroll default
  overflow-y: auto;
  font-size: 100%;
}

@media screen and (max-width: 1440px) {
  html {
    font-size: 90%;
  }
}

// Override bulma default size
.control {
  font-size: $size-7;
}
