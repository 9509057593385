 @import './scss/base/colors';

.p-loader-wrapper {

    margin: 0 auto;
    padding: 8px 0;

    .p-loader {
        width: 48px;
        height: 48px;
        margin: 0px auto;
        border: solid 8px rgba($main-purple, 0.5);
        border-top: solid 8px $main-purple;
        border-radius: 50%;
        animation: spin 2s linear infinite;
        z-index: 1500;
        position: relative;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
