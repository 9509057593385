@import 'colors';

$border-color: $white-6;
$border-color-alt: $grey-1;
$border-style: 1px solid;
$border-dashed-style: 1px dashed;

$border-default: $border-style $border-color;
$border-secondary: $border-style $secondary; // Only used in a specific component
$border-dashed: $border-dashed-style $border-color;
$border-dashed-alt: $border-dashed-style $border-color-alt;

$border-color-white-1: $white-6;
$border-color-white-22: #e9ecee; // TODO : use somewhere else ?

$border-radius-large: 10px;
$border-radius: 5px;
$border-radius-small: 3px;

// TODO : rename ?
$p-radius-rounded: 290486px;

.p-default-border {
    border: 1px solid;
}

// TODO : inconsistent name
.p-border-big {
    @extend .p-default-border;
    border-radius: $border-radius;
}

.p-border-default-color {
    border-color: $divider-color !important;
}

.p-border-selected-color {
    border-color: #54C4D6 !important;
}

// TODO : should be used more
// TODO : rename
.p-border {
    @extend .p-border-default-color;
    @extend .p-default-border;
}

.p-b-small {
    border-radius: $border-radius-small;
}

.p-border-sr {
    @extend .p-border;
    @extend .p-b-small;
}

.p-border-dr {
    @extend .p-border;
    @extend .p-b-small;
    // border-radius: $border-radius;
}

.p-ok-border {
    @extend .p-default-border;
    border-color: rgba($green-2, 0.3) !important;
}

.p-warn-border {
    @extend .p-default-border;
    border-color: $warning;
}

.p-error-border {
    @extend .p-default-border;
    border-color: rgba($error-color, 0.3) !important;
}
