//@import "variables-theme";

@import "./style";
@import "./base/layouts";
@import "./components/form";

$family-sans-serif: $main-family;

// Colors
// ---------------------------------------------------------------------------


$custom-colors: (
  "secondary": ($secondary, $invert-full-white)
);

// Miscellaneous
// ---------------------------------------------------------------------------

$easing: ease-out;
$radius-small: $border-radius-small;
$radius-large: 7px;
$radius-rounded: $p-radius-rounded;

$title-color: $grey-1;
$label-color: $blue-1; // TODO : probably wrong

// Components
// ---------------------------------------------------------------------------

$control-radius: $radius-small; // $radius !default

$control-height: 48px;
$control-line-height: 1.5;
$control-border-width: $p-control-border-width;
// $control-padding-vertical: calc(#{$space-6} - #{$control-border-width});
$control-padding-horizontal: calc(#{$space-5} - #{$control-border-width});


// Buttons
// -----------------

// $button-color: $primary-invert;
// $button-background-color: $primary;

// $button-border-color: $grey-lighter !default
// $button-border-width: 0;

// $button-hover-color: $primary-invert;
// $button-hover-border-color: $link-hover-border !default

// $button-padding-vertical: 0;
// $button-padding-horizontal: 16px;

// $button-focus-color: $primary-invert;
// $button-focus-border-color: $link-focus-border !default
// $button-focus-box-shadow-size: 0 0 0 0.125em !default
// $button-focus-box-shadow-color: rgba($link, 0.25) !default

// $button-active-color: $primary-invert;
// $button-active-border-color: $link-active-border !default

// $button-text-color: $text;
// $button-text-hover-background-color: $background !default
// $button-text-hover-color: $text-strong !default

// $button-disabled-background-color: $white !default
// $button-disabled-border-color: $grey-lighter !default
// $button-disabled-shadow: none !default
// $button-disabled-opacity: 0.5 !default

// $button-static-color: $grey !default
// $button-static-background-color: $white-ter !default
// $button-static-border-color: $grey-lighter !default

// Table
// -----------------

// $table-color: $grey-darker !default
// $table-background-color: $white !default

$table-cell-border: $border-default;
$table-cell-border-width: 0;
$table-cell-padding: 1.5em 2em; // 0.5em 0.75em !default
// $table-cell-heading-color: $text-strong !default

$table-head-cell-border-width: 0 0 1px;
// $table-head-cell-color: $text-strong !default
// $table-foot-cell-border-width: 2px 0 0 !default
// $table-foot-cell-color: $text-strong !default

// $table-head-background-color: transparent !default
// $table-body-background-color: transparent !default
// $table-foot-background-color: transparent !default

// $table-row-hover-background-color: $white-bis !default

// $table-row-active-background-color: $primary !default
// $table-row-active-color: $primary-invert !default

$table-striped-row-even-background-color: $white-4;
// $table-striped-row-even-hover-background-color: $white-ter !default

// Modal
// -----------------

$modal-card-head-radius: $border-radius-small;
$modal-card-foot-radius: $border-radius-small;
$modal-card-head-background-color: $white-0;
$modal-background-background-color: $modal-overlay-color;
$modal-card-title-color: $main-purple;

// Progress
// -----------------

$progress-bar-background-color: $white-4;
$progress-value-background-color: $primary-green;


// Box (we use .box for cards)
// -----------------

// So far boxes are only used in profile

// $box-radius: $border-radius; // Useful ?
// Box only used in profile for now
//$box-shadow: $drop-shadow-huge;

// Divider
// -----------------
$hr-background-color: $white-4; // $background !default
$hr-height: 1px; // 2px !default
// $hr-margin: 1.5rem 0 !default


// Input
// -----------------

// $input-color: $grey-darker !default
// $input-background-color: $white !default

// $input-height: $control-height !default
// $input-shadow: inset 0 1px 2px rgba($black, 0.1) !default
// $input-placeholder-color: rgba($input-color, 0.3) !default

// $input-hover-color: $grey-darker !default
// We Remove
$input-hover-border-color: $border-color-white-1; // Remove  // $border-color-alt; // $grey-light !default

$input-background-color: $p-input-background-color;
$input-border-color: $p-input-border-color;
$input-border-color-focus: $p-input-border-color-focus;

$input-border-color: $border-color;// $grey-lighter !default // Redefining

// $input-focus-color: $grey-darker !default
$input-focus-border-color: $primary-green; // $link !default
// $input-focus-box-shadow-size: 0 0 0 0.125em !default
// $input-focus-box-shadow-color: rgba($link, 0.25) !default

// $input-disabled-color: $text-light !default
// $input-disabled-background-color: $background !default
// $input-disabled-border-color: $background !default
// $input-disabled-placeholder-color: rgba($input-disabled-color, 0.3) !default

// $input-arrow: $link !default

// $input-icon-color: $grey-lighter !default
// $input-icon-active-color: $grey !default

$input-radius: $radius-large;

$body-line-height: normal; // Reset to default

$modal-card-body-padding: 0; // Horizontal bar needs to take full width

$slider-thumb-border: 1px solid $main-purple;
$slider-track-background :linear-gradient(to right, #54C4D6, $main-purple);
//$slider-thumb-background :linear-gradient(to right, blue, white);

// For the static
// $navbar-height: 5rem; // Changes with mobile
$navbar-background-color: $new-purple;
$navbar-item-color: $text-light-color;
$navbar-item-hover-color: $text-light-color;
$navbar-item-hover-background-color: $new-purple; // Necessary for mobile
//$navbar-burger-color: $white-0;
// $navbar-padding-horizontal: 10rem;
