@import './scss/base/colors';
@import './scss/times';

$link-style-danger-resting-text-color: $danger;

.p-link {

    // Default style of the button
    // font-weight: 600; // TODO should be $weight-bold

    transition: $long-time;

    // Remove firefox dotted outline, the outline is taken care of by Bulma
    &::-moz-focus-inner {
        border: 0;
    }

    // Link kinds and states
    &:hover,
    &.is-hovered {
        text-decoration: underline;
    }

    &:focus,
    &.is-focused {
        text-decoration: underline;
    }

    &.is-primary {

        // https://sass-lang.com/documentation/variables
        $plink-primary: #112233 !default;

        $link-style-primary-resting-text-color: $plink-primary; // $secondary;

        color: $link-style-primary-resting-text-color;

        &:focus,
        &.is-focused {
            color: $link-style-primary-resting-text-color, 5%;
        }

        &:active,
        &.is-active {
            color: darken($link-style-primary-resting-text-color, 5%);
        }

        &:disabled,
        &.is-disabled {
            color: $link-style-primary-resting-text-color;
            opacity: $fade-2;
        }
    }

    &.p-p-white-blue-background {
        $link-style-primary-resting-text-color: $white-0;

        color: $link-style-primary-resting-text-color;

        &:focus,
        &.is-focused {
            color: $link-style-primary-resting-text-color, 5%;
        }

        &:active,
        &.is-active {
            color: darken($link-style-primary-resting-text-color, 5%);
        }

        &:disabled,
        &.is-disabled {
            color: $link-style-primary-resting-text-color;
            opacity: $fade-2;
        }
    }

    .p-p-blue-background {
        color: $secondary-color;
        font-weight: 700;
    }

    &.is-danger {
        color: $link-style-danger-resting-text-color;

        &:hover,
        &.is-hovered {
            color: $link-style-danger-resting-text-color;
        }

        &:focus,
        &.is-focused {
            color: $link-style-danger-resting-text-color;
        }

        &:active,
        &.is-active {
            color: darken($link-style-danger-resting-text-color, 5%);
        }

        &:disabled,
        &.is-disabled {
            color: $link-style-danger-resting-text-color;
            opacity: $fade-2;
        }
    }
}
