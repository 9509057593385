
@import '../base/colors';
@import '../base/borders';
@import '../base/typography';

// TODO : remove
// @import '../base/layouts';

$p-input-background-color: $white-0; // Bulma and Manual Edit Form only
$p-input-border-color: $border-color-white-1; // Bulma and Manual Edit Form only
$p-input-border-color-focus: $green-2-alt; // Bulma and Manual Edit Form only

$input-active-border-color: $green-2;


$p-control-border-width: 1px; // Was from Bulma

$control-default-height: 48px; // 52px;
$control-default-width: 240px; // TODO : remove ?

$control-compact-height: 42px;
$control-compact-width: 140px;


// Misc.
// -----------------

$drop-shadow-size-default: 0 2px 4px 0;
$drop-shadow-size-huge: 0 10px 15px 0;
$drop-shadow-size-huge-around: 0 -2px 15px 0;

// $drop-shadow-default: $drop-shadow-size-default $white-6;
$drop-shadow-huge: $drop-shadow-size-huge $white-6;
$drop-shadow-huge-around: $drop-shadow-size-huge-around $white-6;

$input-active-box-shadow: $drop-shadow-size-default $primary-green-fade-1;


// TODO : needed ?
.input {
  font-family: $main-family;
}

.p-input-hidden {
  display: none;
}

.p-ball {
  height: 6px;
  width: 6px;
  border-radius: 10px;
  background-color: $white-6;
  display: inline-block;
}

.p-base-control {
  height: $control-default-height;
}

.p-control {
  @extend .p-base-control;
  @extend .p-size-default;

  // width: $control-default-width;
  // TODO : use bulma input radius ?
  border-radius: $border-radius-small !important;
  border: 1px solid $white-6;
}

.p-search-box {
  @extend .p-control;
  &.active,
  &.is-focused {
      border-color: $input-active-border-color;
      box-shadow: $input-active-box-shadow;
  }
  height: 30px;
  border-radius: 30px !important;
}

// TODO : weird name
.p-i-square {
  width: $control-default-height;
}

.p-control-compact-height {
  height: $control-compact-height;
}
