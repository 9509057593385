// Color palette --------------------------------------------------------------
// None of these variables should be used outside of this section


$white-0:       #ffffff;
$white-1:       #fefefe;
$white-2:       #fafafa;
$white-4:       #f5f6f8; // Mainly background
$white-6:       #dee3e6;

$grey-1:          #666666; // borders, bulma title, text
$grey-2:          #95A6B0; // Used by Etienne Modal which is not used
$grey-3:          #484E4D;
$grey-4:          #bbc6cd;
$grey-5:          #8694a8;

// TODO : default ?
$grey-title-text: #394250;

$green-2:       #5ecae2;  // #58cae4; // #32dac3; // Out main green
$green-3:       #32dac3;
$green-2-alt:   #24c6b0; // Hoover and focus

$blue-1:           #687782; // For bulma label colors, taken for init claim color

// Used for logo, for fill
$new-purple: #503ecc;

$main-purple: $new-purple; // #696b9c;

$warning:         #F7A253; // Warning
$error-color:   #ff6677;

// Used in profile card & topbar
$error-indicator-color: #ef636e; // TODO : why not the previous one ?
$warning-indicator-color: #ffe339;


$success: $green-2;

$incomplete-indicator-color: $main-purple;
$complete-indicator-color: $success;

$secondary-row-color: #fbfcfe;

$background-filter-color: $white-4;

$focus-color: $green-2;

$pending-color: #fffbe4;


// Highlights

$main-light-purple: #797aa7;
$main-light-purple-hover: #9395b8;
$main-dark-purple: #3f4160;

$secondary-color: $green-2;
$secondary-color-hover: $green-2-alt;

// Background

$background-color: $white-1;
$background-high-color: $green-2;

$progress-bar-background-color: #a5efe5;


// From Etienne

// Opacities

$fade-1: .18;
$fade-2: .4;

$primary-green: $green-2; // Celui des bouttons
$primary-green-alt: #65d5f0;//  #35E8D0;
$primary-green-fade-1: rgba($primary-green, $fade-1);

$secondary: $new-purple; // #636998;
$secondary-alt: #5e49f2; // #767DB5;

$danger: #EF616C; // TODO : rename to danger-color ?

$danger-fade-1: rgba($danger, $fade-1);

$divider-color: $white-6;
