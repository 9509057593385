@import '../scss/base/typography';

.p-topbar {

    @media screen and (max-width: 768px) {
        padding-bottom: 10px;
        padding-top: 10px;
    }

    // TODO
    // height: $topbar-height;
    background: $text-light-color;
    border-bottom: solid 1px $divider-color;
}
